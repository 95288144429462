import { Box, Button } from "@mui/material";
import React from "react";
import { I18n } from '@aws-amplify/core';
import TranslateIcon from "@mui/icons-material/Translate";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ContactPageIcon from "@mui/icons-material/ContactPage";
// import LanguageIcon from "@mui/icons-material/Language";
function ButtonNav({activeTab, setActiveTab}) {

  const tabButtonStyles = {
    textTransform: "none",
    fontWeight: "bold",
    borderColor: "#e3e3e3",
    borderRadius: "4px",
    marginRight: "8px",
  };

  const secondaryButtonStyles = {
    textTransform: "none",
    color: "rgba(46, 125, 50, 1)",
    fontWeight: "bold",
    borderColor: "rgba(46, 125, 50, 0)",
    borderRadius: "4px",
    marginRight: "8px",
  };

  const secondaryButtonStylesActive = {
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.05)",
    borderColor: "rgba(46, 125, 50, 0.3)",
    color: "rgba(46, 125, 50, 1)",
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: "4px",
    marginRight: "8px",
  };

  return (
    <>
    <Box style={{ margin: "1rem" }}>
      <Button key={1} variant={activeTab === "txt" ? "contained": "outlined"} style={tabButtonStyles} sx={{ mt: { xs: "0.5rem", md: "0" } }} startIcon={<TranslateIcon />} onClick={() => setActiveTab("txt")}>
        <span className="text-alignment">{I18n.get("tab-txt")}</span>
      </Button>
      <Button key={2} variant={activeTab === "doc" ? "contained": "outlined"} style={tabButtonStyles} sx={{ mt: { xs: "0.5rem", md: "0" } }} startIcon={<ContactPageIcon />} onClick={() => setActiveTab("doc")}>
        <span className="text-alignment">{I18n.get("tab-doc")}</span>
      </Button>
      <Button key={3} variant="outlined" style={activeTab == "doc-correct" ? secondaryButtonStylesActive : secondaryButtonStyles} sx={{ mt: { xs: "0.5rem", md: "0" } }} startIcon={<SaveAsIcon />} color="success" onClick={() => setActiveTab("doc-correct")}>
        <span className="text-alignment" style={{textDecoration:"inherit"}}>{I18n.get("tab-doc-correct")}</span>
      </Button>
    </Box>
    </>
  );
}

export default ButtonNav;
