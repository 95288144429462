import { Box, IconButton, Button, Tooltip} from "@mui/material";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ThumbsUpDownOutlinedIcon from "@mui/icons-material/ThumbsUpDownOutlined";
// import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import Chip from "@mui/material/Chip";
import LinearProgress from '@mui/material/LinearProgress';
import { I18n } from '@aws-amplify/core';


import { post } from "aws-amplify/api";


function RightTextArea({ fetchedText, setFetchedText, requestIdValue, workgroupValue, translate_loading }) {
  const textareaRef = useRef(null);

  const [evaluationProgressStatus, setEvaluationProgressStatus] = useState(0);
  const [requestId, setRequestId] = useState("");
  const [workgroup, setWorkgroup] = useState("");

  const [editModeStatus, setEditModeStatus] = useState(false);

  const [charCount, setCharCount] = useState(0);

  const [copyStatus, setCopyStatus] = useState("");

  const [editedText, setEditedText] = useState("");

  const handleEditTextChange = (e) => {
      adjustHeight();
      setEditedText(e.target.value);
      // setCharCount(e.target.value.length);
  };

  const handleExitEditMode = () => {
    setEditModeStatus(false)
  };

  const handleSaveEditMode = () => {
    setFetchedText(editedText)
    submitCorrectionAPICall(requestId, editedText, workgroup)
    // setEditModeStatus(false)
  };

  const focusTextArea = () => {
    textareaRef.current && textareaRef.current.focus()
  }

  useEffect(() => {
    adjustHeight();
    setCharCount(fetchedText.length);
    if (!editModeStatus) {
      setEditedText(fetchedText)
    }
    
  }, [fetchedText]);

  
  useEffect(() => {
    setRequestId(requestIdValue);
  }, [requestIdValue]);

  useEffect(() => {
    setWorkgroup(workgroupValue);
  }, [workgroupValue]);


  function submitEvaluation(value) {
    setEvaluationProgressStatus(2);
    // Send data
    submitEvaluationAPICall(requestId, value, workgroup)

    // After 10 seconds, go back to status 0 --> timeout
    setTimeout(() => {
      setEvaluationProgressStatus(0);
    }, 3000);
  }

  async function submitEvaluationAPICall(id, evaluation, workgroup) {
    try {
      const evaluationRequest = { id: id, evaluation: evaluation, workgroup: workgroup};
      /*
      Request:
          Id              string `json:"id"`
          EvaluationValue int    `json:"evaluation"` // 0, 1, and 2
          Workgroup string `json:"workgroup"`

      Response:
          Id        string `json:"id"`
          Success   bool   `json:"success"`
          Attention string `json:"attention"`

      */

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/v1/api/evaluate",
        options: {
          body: evaluationRequest,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && bodyJson.success) {
        setTimeout(() => {
          setEvaluationProgressStatus(0);
        }, 2000);
      } else {
        setTimeout(() => {
          setEvaluationProgressStatus(0);
        }, 2000);
      }
    } catch (err) {
        setTimeout(() => {
          setEvaluationProgressStatus(0);
        }, 2000);
    }
  }

  async function submitCorrectionAPICall(requestId, editedText, workgroup) {
    try {
      const correctionRequestBody = { id: requestId, correction: editedText, workgroup: workgroup };
      /*
      Request:
          Id         string `json:"id"`
          EditedText string `json:"correction"`
          Workgroup string `json:"workgroup"`

      Response:
          Id        string `json:"id"`
          Success   bool   `json:"success"`
          Attention string `json:"attention"`

      */

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/v1/api/txtcorrection",
        options: {
          body: correctionRequestBody,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && bodyJson.success) {

        setEditModeStatus(false)

      } else {
        setEditModeStatus(false)
      }
    } catch (err) {
      setEditModeStatus(false)
    }
  }

  async function copyTextToClipboard() {
    try {
      await navigator.clipboard.writeText(fetchedText);
      setCopyStatus("success");
      await new Promise(r => setTimeout(r, 2000));
      setCopyStatus("");
    } catch (err) {
      console.error('Failed to copy translation: ', err);
    }
  }

  async function copyTextToClipboardOld() {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(fetchedText);
    } else {
      return document.execCommand('copy', true, fetchedText);
    }
  }

  function adjustHeight() {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);

  return (
    <Box>
      <Box
        sx={{
          border: 1,
          padding: "0.25rem",
          borderColor: "divider",
          width: "100%",
          minHeight: "175px",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#f5f5f5",
        }}
        onClick={focusTextArea}
      >
        <div className="input-container" style={{ display: "flex", borderTopLeftRadius: "inherit" }}>
          <textarea
            disabled={!editModeStatus}
            value={!editModeStatus ? (fetchedText):(editedText)}
            style={{
              marginRight: "5%",
              border: "none",
              borderTopLeftRadius: "inherit",
              outline: "none",
              padding: "10px",
              fontSize: "20px",
              backgroundColor: "#f5f5f5",
              width: "90%",
              resize: "none",
              color: "#282828"
            }}
            ref={textareaRef}
            className="dynamic-textarea"
            id="translatedTextInput"
            onChange={handleEditTextChange}
          ></textarea>
          { translate_loading && (
            <LinearProgress />
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton style={{ marginBottom: "0rem", marginLeft: "0rem" }} disabled={fetchedText.toString().length===0}>
          {/*!editModeStatus && (
            <VolumeUpIcon sx={{ color: "gray" }} />
          )*/}
          {editModeStatus && (
            <CloseIcon sx={{ color: "gray" }} onClick={() => handleExitEditMode()}/>
          )}
          </IconButton>
          <div>
            {!editModeStatus && evaluationProgressStatus === 0 && ( // Not evaluating
              <>
                <Tooltip disabled={fetchedText.toString().length===0} title={copyStatus == "success" ? I18n.get("copy-success") : I18n.get("copy")}>
                  <IconButton onClick={() => copyTextToClipboard()} >
                    {copyStatus == "success" ? 
                      <CheckIcon sx={{ color: "green" }} />
                      : <ContentCopyIcon sx={{ color: "gray" }} />
                    }
                  </IconButton>
                </Tooltip>
                <Tooltip disabled={fetchedText.toString().length===0} title={I18n.get("evaluate-translation")}>
                  <IconButton onClick={() => setEvaluationProgressStatus(1)}>
                    <ThumbsUpDownOutlinedIcon sx={{ color: "gray" }} />
                  </IconButton>
                </Tooltip>
                {/* <IconButton disabled={fetchedText.toString().length===0}>
                  <ShareOutlinedIcon sx={{ color: "gray" }} onClick={() => console.log("share")} />
                </IconButton> */}
                <Tooltip disabled={fetchedText.toString().length===0} title={I18n.get("suggest-correction")}>
                  <IconButton onClick={() => setEditModeStatus(1)}>
                    <EditIcon sx={{ color: "gray" }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!editModeStatus && evaluationProgressStatus === 1 && charCount > 0 &&  ( // Ask for evaluation
              <>
                <IconButton onClick={() => submitEvaluation(2)}>
                  <SentimentSatisfiedAltIcon sx={{ color: "green" }} />
                </IconButton>
                <IconButton onClick={() => submitEvaluation(1)}>
                  <SentimentNeutralIcon sx={{ color: "orange" }} />
                </IconButton>
                <IconButton  onClick={() => submitEvaluation(0)}>
                  <SentimentVeryDissatisfiedIcon sx={{ color: "red" }} />
                </IconButton>
              </>
            )}
            {!editModeStatus && evaluationProgressStatus === 2 && charCount > 0 &&  ( // Thanks
              <>
                <Chip primary sx={{ mr: "2rem", pl: "1rem", pr: "1rem" }} icon={<ThumbUpAltIcon />} label="Eskerrik asko!" />
              </>
            )}
            {editModeStatus && ( // Edit mode enabled > Show save icon
              <>
                <IconButton sx={{marginRight: "0.5rem"}} onClick={() => handleSaveEditMode()}>
                  <SaveIcon sx={{ color: "gray" }} />
                </IconButton>
              </>
            )}
            
          </div>
        </div>
      </Box>
      <div style={{height: "2px", padding: "0px 8px"}}>
            { translate_loading && (
              <LinearProgress sx={{height:"2px"}}/>
            )}
          </div>
    </Box>
  );
}

export default RightTextArea;
