import React from "react";
import { I18n } from "@aws-amplify/core";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import DocCorrectionUploadArea from "./DocCorrectionUploadArea";

function DocCorrect() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        flexDirection: { xs: "column", md: "row" },
        m: "1rem",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ margin: "2rem 3rem" }}>
          {I18n.get("tab-doc-correct-disclaimer")}
          <br/><br/>
          - {I18n.get("tab-doc-correct-disclaimer-greetings")}
        </Typography>

        <DocCorrectionUploadArea />
        <Typography variant="caption" color="text.secondary" sx={{ m: "0", ml: "1rem" }}>
          + info:{" "}
          <Link color="inherit" href={I18n.get("footer-usageterms-link")} target="_blank">
            {I18n.get("footer-usageterms")}
          </Link>{" "}
          {I18n.get("hitza-eta")}{" "}
          <Link color="inherit" href={I18n.get("footer-privacypolicy-link")} target="_blank">
            {I18n.get("footer-privacypolicy")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default DocCorrect;
