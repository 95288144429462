import { Box, IconButton, Typography, List, ListItemText, Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction } from "@mui/material";
import { I18n } from "@aws-amplify/core";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { toTitleCase } from "./Utils";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { fetchUserAttributes } from "aws-amplify/auth";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#080",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const buttonStyles = {
  textTransform: "none",
  fontWeight: "bold",
  borderColor: "#e3e3e3",
  borderRadius: "4px",
  marginRight: "8px",
  // width: "auto",
};

function DocCorrectionUploadArea(props) {
  const [acceptedFilesState, setAcceptedFilesState] = useState([]);
  const [rejectedFilesState, setRejectedFilesState] = useState([]);

  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [userOrg, setUserOrg] = useState("");

  const [docCorrectionUploadModeStatus, setDocCorrectionUploadModeStatus] = useState(false);
  const [isCorrectionSubmitProcessing, setIsCorrectionSubmitProcessing] = useState(false);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      // "application/rtf": [],
      // "text/rtf": [],
      // "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/vnd.oasis.opendocument.text": [],
      "application/x-xliff+xml": [],
      "application/x-xliff": [],
      "text/html": [],
      "application/xml": [],
      "text/xml": [],
      "text/plain": [".txt", ".po"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/x-gettext-translation": [],
      "application/vnd.adobe.indesign-idml-package": [],
      "text/markdown": [],
      "text/x-markdown": [],
    },
    maxFiles: 1,
    noClick: isCorrectionSubmitProcessing,
    onDrop: (acceptedFiles) => {
      setAcceptedFilesState(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function clearAll() {
    // Clear and reset
    setAcceptedFilesState([]);
    setRejectedFilesState([]);
    setIsCorrectionSubmitProcessing(false);
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    // Get user attributes
    handleFetchUserAttributes();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      setUserEmailAddress(userAttributes.email);
      if (userAttributes.zoneinfo && userAttributes.zoneinfo !== "") {
        setUserOrg(toTitleCase(userAttributes.zoneinfo));
      }else{
        setUserOrg("vicomtech");
      }
      
    } catch (error) {
      // console.log(error);
      // TODO logout
    }
  }

  return (
    // <Box>
    <Box
      sx={{
        border: 1,
        padding: 1,
        borderColor: "divider",
        // width: "98%",
        minHeight: "150px",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // backgroundColor: "",
      }}
    >
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} disabled={isCorrectionSubmitProcessing} />
          <CloudUploadIcon />
          <p>{I18n.get("tab-doc-correct-drop-explanation")}</p>
          <em>{I18n.get("tab-doc-correct-drop-filetypes")}</em>
        </div>
        {/* <aside> */}
        {acceptedFilesState.length > 0 && (
          <div className="">
            <List dense={true}>
              <ListItem disableGutters>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 0 }} justify="flex-end" alignItems="center">
                  <Grid item xs={12} sm={8} md={9}>
                    <Stack direction={"horizontal"} justifyContent="right" justify="flex-end" alignItems="center">
                      <Avatar variant="rounded" sx={{ width: 32, height: 32, bgcolor: "#ffffff" }}>
                        <IconButton
                          // variant="outlined"
                          color="warning"
                          disabled={isCorrectionSubmitProcessing}
                          // style={buttonStyles}
                          onClick={() => clearAll()}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Avatar>
                      <ListItemText primary={acceptedFilesState[0].path} secondary={acceptedFilesState[0].size + " bytes"} />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Stack
                      direction={"horizontal"}
                      sx={{ justifyContent: { xs: "center", sm: "right", md: "right" }, mt: { xs: "1rem", sm: "0rem", md: "0rem" } }}
                    >
                      <LoadingButton
                        key={1}
                        variant="outlined"
                        style={buttonStyles}
                        sx={{ mt: { xs: "0.5rem", md: "0" } }}
                        startIcon={<SendIcon />}
                        onClick={() => setIsCorrectionSubmitProcessing(!isCorrectionSubmitProcessing)}
                        loading={isCorrectionSubmitProcessing}
                        loadingPosition="start"
                        // color="green"
                        // disabled={(translateFromLanguag !== "" && translateToLanguag !== "") ? false:true}
                      >
                        <span>
                          {!isCorrectionSubmitProcessing ? I18n.get("tab-doc-correct-upload-button") : I18n.get("tab-doc-correct-working")}
                        </span>
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
                {/* <ListItemSecondaryAction>

                  <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      >

                   

                  <LoadingButton
                    key={1}
                    variant="outlined"
                    style={buttonStyles}
                    sx={{ mt: { xs: "0.5rem", md: "0" } }}
                    startIcon={<SendIcon />}
                    onClick={() => setIsCorrectionSubmitProcessing(!isCorrectionSubmitProcessing)}
                    loading={isCorrectionSubmitProcessing}
                    loadingPosition="start"
                    // color="green"
                    // disabled={(translateFromLanguag !== "" && translateToLanguag !== "") ? false:true}
                  >
                    <span>{!isCorrectionSubmitProcessing ? (I18n.get("tab-doc-correct-upload-button")) : (I18n.get("tab-doc-correct-working"))}</span>
                  </LoadingButton>
                  </Stack>
                </ListItemSecondaryAction> */}
                {/* <ListItemAvatar>
                  <Avatar variant="rounded" sx={{ width: 32, height: 32, bgcolor: "#ffffff" }}>
                    <IconButton
                      // variant="outlined"
                      color="warning"
                      disabled={isCorrectionSubmitProcessing}
                      // style={buttonStyles}
                      onClick={() => clearAll()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={acceptedFilesState[0].path} secondary={acceptedFilesState[0].size + " bytes"} /> */}
              </ListItem>
            </List>
          </div>
        )}
        {rejectedFilesState.length > 0 && (
          <>
            <Typography variant="span" color="red">
              {I18n.get("tab-doc-correct-file-error-1")}
            </Typography>
          </>
        )}
        {/* </aside> */}
      </section>
    </Box>
    // </Box>
  );
}

export default DocCorrectionUploadArea;
