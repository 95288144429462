
import { I18n } from "@aws-amplify/core";
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { Check, Close } from "@mui/icons-material";
import { Alert, IconButton, AlertTitle, Divider } from "@mui/material";


function GlossarySelector({ glossary, setGlossary, glossaryList, selectedLanguages, defaultGlossary}) {

  const [checked, setChecked] = React.useState(defaultGlossary.id ? true : false);
  const [lastGlossary, setLastGlossary] = React.useState(defaultGlossary.id);
  const [supportedLanguages, setSupportedLanguages] = React.useState(defaultGlossary.languages)
  const [label, setLabel] = React.useState(defaultGlossary.displayName);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const glossaryComponentRef = React.useRef();

  const handleOpen = () => {
    setAnchorEl(glossaryComponentRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if(glossary === ''){
        setChecked(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'glossary-popover' : undefined;

  const handleGlossaryChange = (glossary_id, label, compatibleLangs) => {
    setGlossary(glossary_id);
    setLastGlossary(glossary_id);
    setLabel(label ? label : "");
    setSupportedLanguages(compatibleLangs ? compatibleLangs : []);
    if (glossary_id === ''){
        setChecked(false);
        setLabel('');
    }else{
        setChecked(true);
    }
    setAnchorEl(null);
  };

  const handleSwitch = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
    if(event.target.checked){
        setGlossary(lastGlossary);
        if(lastGlossary === ''){
            setAnchorEl(glossaryComponentRef.current);
        }
    } else {
        setGlossary("");
    }
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(20px)',
            '& + .MuiSwitch-track': {
                backgroundColor: 'primary.main',
                opacity: 1,
                border: 0
            }
        }
    },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="m5 6.5 5.5 5.5L5 17.5 6.5 19l5.5-5.5 5.5 5.5 1.5-1.5-5.5-5.5L19 6.5 17.5 5 12 10.5 6.5 5z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

    const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip 
        {...props} 
        classes={{ popper: className }} 
        slotProps={{
            popper: {
                modifiers: [
                {
                    name: 'offset',
                    options: {
                    offset: [0, -11],
                    },
                },
                ],
            },
        }}
    />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: 0
        },
    }));

    return (
        <>
            <Stack
                direction="row" 
                spacing={0.5} 
                sx={{justifyContent: "space-around", alignItems: "center", border: "1px solid #d4d4d4",
                    borderRadius: "0.5rem",}}
                ref={glossaryComponentRef}
            >
                {
                    checked && !(supportedLanguages.includes(selectedLanguages[0]) && supportedLanguages.includes(selectedLanguages[1])) ?
                        
                        <HtmlTooltip
                            title={
                                <Alert severity="warning" color="info">
                                    <AlertTitle
                                        dangerouslySetInnerHTML={{__html: I18n.get("glossary-unsupported").replace("::glossary::", "<b>"+label+"</b>")}}
                                    />
                                    <Stack 
                                        direction="column"
                                        spacing={1}
                                    >
                                        <Stack 
                                            direction="row"
                                            spacing={0.75}
                                        >
                                            {supportedLanguages.map((l)=>(<Typography variant="caption" sx={{padding: "0.25rem 0.45rem 0.05rem", fontWeight: "semibold", borderRadius: 3, border: "1px solid #3d8db3"}}>{I18n.get("languages-"+l)}</Typography>))}
                                        </Stack>
                                        <Divider />
                                        <Typography variant="body2">{I18n.get("glossary-unsupported2")}</Typography>
                                    </Stack>
                                    
                                </Alert>
                            }
                        >
                            <div>
                                <CustomSwitch checked={false} disabled/>
                            </div>
                        </HtmlTooltip>
                    :
                        <CustomSwitch checked={checked} onChange={handleSwitch} inputProps={{ 'aria-label': 'Glossary Switch' }} />

                }
                <Stack 
                    direction="row"
                    spacing={0.5}
                    sx={{
                        padding: "5px 0.75rem 0 0 ",
                        '&:hover': {
                            cursor: "pointer",
                            color: "primary.main"
                        }
                    }}
                    onClick={handleOpen}
                >
                    <Typography>{I18n.get("glossary")+(label!=="" ? " - "+label : "")}</Typography>
                </Stack>
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
            >
                <Box>
                    <Stack direction="row" spacing={0.5} sx={{borderBottom: "1px solid #d4d4d4", minWidth: "min(20rem, 90vw)", paddingLeft: "0.5rem", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{padding: "5px 0 0 5px"}}>{I18n.get("glossary")}</Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Stack direction="column" spacing={1} sx={{p: 2}}>
                        <Stack direction="column" spacing={0.5}>
                            <MenuItem 
                                value={""}
                                onClick={()=>{handleGlossaryChange("")}}
                                selected={"" === lastGlossary}
                            >
                                    <Stack direction="row" spacing={1}>
                                        <Check sx={lastGlossary !== "" && {opacity:  '0'}} />
                                        <Typography>{I18n.get("glossary-none")}</Typography>
                                    </Stack>
                            </MenuItem>
                            {
                                glossaryList.map((g) => (
                                    <MenuItem 
                                        value={g.id} 
                                        onClick={()=>{handleGlossaryChange(g.id, g.displayName, g.languages)}}
                                        selected={g.id === lastGlossary}
                                    >
                                        <Stack direction="row" spacing={0.5} sx={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                            <Stack direction="row" spacing={1}>
                                                <Check sx={lastGlossary !== g.id && {opacity: '0'}} />
                                                <Typography>{g.displayName}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={0.5}>
                                                {g.languages.map((l) => (
                                                    <Chip label={I18n.get("languages-"+l)} size="small" sx={{paddingTop: "2px"}}/>
                                                ))}
                                            </Stack>
                                            {/*(g.languages.includes(selectedLanguages[0]) && g.languages.includes(selectedLanguages[1])) && <Check/> */}
                                        </Stack>
                                    </MenuItem>
                                ))
                            }
                        </Stack>
                        <Alert severity="info" sx={{maxWidth: "30rem"}}>{I18n.get("glossary-disclaimer")}</Alert>
                    </Stack>
                    
                </Box>
                
            </Popover>
        </>
    );
}

export default GlossarySelector;

  