import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Link from "@mui/material/Link";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { I18n } from "@aws-amplify/core";





function HelpDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog 
            onClose={handleClose} 
            open={open}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{I18n.get("help")}</DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent 
                sx={{
                    display: 'flex',
                    justifyContent: "center"
                }}
            >
                <video controls width="100%" style={{ maxWidth: "100%", maxHeight: "calc(100vh - 264px)"}} poster={I18n.get("help-video-poster-url")}>
                    <source src={I18n.get("help-video-url")} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </DialogContent>
            <DialogActions>
            <Link 
                color="primary" 
                href= {I18n.get("help-contact-url")} 
                target="_blank" 
                sx={{
                    m: '1rem'
                }}
            >
                <Button variant="outlined" startIcon={<ContactSupportIcon />}>
                {I18n.get("help-contact-action")}
                </Button>
            </Link>
            
            </DialogActions>
        </Dialog>
    );
}

export default HelpDialog;
  