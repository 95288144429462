import React, { useState, useEffect, useContext } from "react";
import { I18n } from "@aws-amplify/core";
import { useAuthenticator, Link } from "@aws-amplify/ui-react";
import { fetchUserAttributes, signOut } from "aws-amplify/auth";
import { downloadData } from 'aws-amplify/storage';
import { Box, Fab } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Tooltip from '@mui/material/Tooltip';
import { toTitleCase } from "./Utils";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import UserLanguageContext from "./../UserLanguageContext";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpDialog from "./HelpDialog";
// import Stack from "@mui/material/Stack";

import logo from "../images/logo.png";
// import { ConsoleLogger } from "aws-amplify/utils";

function Header(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [userSub, setUserSub] = useState("");
  const [userName, setUserName] = useState("");
  const [userFamilyName, setUserFamilyName] = useState("");
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [userOrg, setUserOrg] = useState("");
  const [userOrgName, setUserOrgName] = useState("");

  const [userProfilePopoverEl, setUserProfilePopoverEl] = useState(null);

  const open = Boolean(userProfilePopoverEl);
  const id = open ? "simple-popover" : undefined;

  const { language, setLanguage } = useContext(UserLanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const [helpOpen, setHelpOpen] = useState(false);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      // console.log("userAttributes");
      // console.log(userAttributes);
      
      if (userAttributes.sub !== "") {
        setUserSub(userAttributes.sub);
      }
      if (userAttributes.email !== "") {
        setUserEmailAddress(userAttributes.email.toLowerCase());
      }
      if (userAttributes.middle_name !== "") {
        setUserName(toTitleCase(userAttributes.middle_name));
      }
      if (userAttributes.family_name !== "") {
        setUserFamilyName(toTitleCase(userAttributes.family_name));
      }
      if (userAttributes.zoneinfo && userAttributes.zoneinfo !== "") {
        setUserOrg(toTitleCase(userAttributes.zoneinfo));
        getEntityConfig(userAttributes.zoneinfo);
      }else{
        setUserOrg("vicomtech");
      }
    } catch (error) {
      // console.log(error);
      // TODO logout
    }
  }

  async function getEntityConfig(entityId) {
      try {
        const downloadResult = await downloadData({ 
          key: `config/${entityId}.json`
          
        }).result;
        const text = await downloadResult.body.text();
        const config = JSON.parse(text);
        setUserOrgName(config.displayName);
      } catch (error) {
        //console.log('Error : ', error);
      }
    }

  const handleUILanguageChange = (e) => {
    const l = e.target.value;
    setSelectedLanguage(l);
    I18n.setLanguage(l);
    setLanguage(l);
    localStorage.setItem("lang", l);
  };

  useEffect(() => {
    // Get user attributes
    handleFetchUserAttributes();
  }, []);

  const handleUserProfilePopoverClick = (e) => {
    setUserProfilePopoverEl(e.currentTarget);
  };

  const handleUserProfilePopoverClose = () => {
    setUserProfilePopoverEl(null);
  };

  const handleClickHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleClickHelpClose = () => {
    setHelpOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          height: "68px",
          borderBottom: "1px solid rgba(0,0,0,.12)",
          p: "8px 16px 8px 16px",
        }}
      >
            <Box className="header__left" sx={{ display: "flex", alignItems: "center" }} justify="flex-end" alignItems="center">
              <Grid container justifyContent="right" justify="flex-end" alignItems="center">
                <Link href="/">
                  <Box
                    component="img"
                    src={logo}
                    alt="logo"
                    sx={{
                      width: "150px",
                      height: "auto",
                      display: { xs: "block", sm: "block", md: "block" },
                    }}
                  />
                </Link>
                <Typography variant="body2" sx={{ ml: "1rem", mr: "1.3rem", color: "gray", display: { xs: "none", md: "block" } }}>
                  {" "}
                  <code className="text-alignment">{I18n.get("secondaryTitle")}</code>
                </Typography>
              </Grid>
            </Box>
            <Box
              className="header__right"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1rem"
              }}
            >
              <Box>
                  <Typography variant="body1" sx={{ mt: '4px', display: { xs: "none", md: "block" } }}>
                    {(userName) && I18n.get("header-kaixo")+", "+userName}
                  </Typography>
              </Box>
              
              <Tooltip title={I18n.get("account")}>
                <Fab alt={user.username} size="small" color="primary" aria-label="account" 
                    sx={{ boxShadow: 0, width: '36px', height: '36px' }}
                    onClick={handleUserProfilePopoverClick}
                >
                  <PersonIcon />
                </Fab>
              </Tooltip>

              <Tooltip title={I18n.get("help")}>
                <IconButton
                  onClick={handleClickHelpOpen}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>

              <FormControl sx={{ m: 0, p: 0, minWidth: 40 }} size="small">
                <Select id="ui-language-select" value={selectedLanguage} onChange={handleUILanguageChange}>
                  <MenuItem value={"eu"}>eu</MenuItem>
                  <MenuItem value={"es"}>es</MenuItem>
                  <MenuItem value={"en"}>en</MenuItem>
                </Select>
              </FormControl>
            </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={userProfilePopoverEl}
        onClose={handleUserProfilePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{vertical: -5, horizontal: -20}}
        sx={{ marginRight: "1rem" }}
      >
        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Id: {userSub}
              </Typography>
              <Typography variant="h5" component="div">
                {userName} {userFamilyName}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {userEmailAddress}
              </Typography>
              <Typography variant="body2">
                {I18n.get("usercard-workgroup")}: <strong>{userOrgName ? userOrgName : toTitleCase(userOrg)}</strong>
              </Typography>
            </CardContent>
            <CardActions sx={{ padding: "0 1rem 1rem 1rem" }}>
              <Button variant="outlined" size="small" startIcon={<LogoutIcon />} onClick={() => signOut()}>
                <span className="text-alignment">{I18n.get("usercard-logout")}</span>
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Popover>

      <HelpDialog
        open={helpOpen}
        onClose={handleClickHelpClose}
      />

    </>
  );
}

export default Header;
