const languages = {   
    eu: {     
        'mainTitle': 'batuago, zure batua.eus kontua',
        'secondaryTitle': "Erabilera aurreraturako interfazea",
        'batuamantra': "Batua.eus itzultzailea erabiltzen duzun bakoitzean, Euskararen eta euskalkien itzulpen-zerbitzuen teknologiak hobetzen laguntzen diguzu.",
        'header-kaixo': "Kaixo",
        'usercard-workgroup': "Lantaldea",
        'usercard-logout': "Irten",
        "footer-usageterms":"Erabilera baldintzak",
        "footer-privacypolicy":"Pribatutasun politika",
        "footer-cookiepolicy":"Cookie politika",
        "footer-usageterms-link":"https://www.batua.eus/erabilera-baldintzak",
        "footer-privacypolicy-link":"https://www.batua.eus/pribatutasun-politika",
        "footer-cookiepolicy-link":"https://www.batua.eus/cookie-politika",
        "empty-destination-lang": "Aukeratu jatorrizko hizkuntza eta hasi idazten",
        "languages-detect": "Hizkuntza detektatu",
        "languages-eu": "Euskara - Batua",
        "languages-es": "Gaztelania",
        "languages-en": "Ingelesa",
        "languages-fr": "Frantsesa",
        "languages-bz": "Bizkaiera",
        "tab-txt": "Testua",
        "tab-doc": "Dokumentuak",
        "tab-doc-correct": "Zuzenketa bidali",
        "tab-doc-drop-explanation": "Klik egin aukeratzeko edo askatu dokumentua eremu honetan",
        "tab-doc-drop-filetypes": "(xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml eta md formatuko dokumentuak soilik onartzen dira)",
        "tab-doc-file-error-1": "Mesedez aukeratu fitxategi bakarra eta formatu aproposekoa.",
        "tab-doc-upload-and-translate-button": "Itzuli",
        "tab-doc-working": "Itzulpena egiten...",
        "hitza-eta": "eta",
        "tab-doc-correct-drop-explanation": "Klik egin aukeratzeko edo askatu zuzenketaren dokumentua eremu honetan",
        "tab-doc-correct-drop-filetypes": "(xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml eta md formatuko dokumentuak soilik onartzen dira)",
        "tab-doc-correct-file-error-1": "Mesedez aukeratu fitxategi bakarra eta formatu aproposekoa.",
        "tab-doc-correct-upload-button": "Bidali zuzenketa",
        "tab-doc-correct-working": "Bidaltzen...",
        "tab-doc-correct-disclaimer": "Batua.eus itzultzailea, erabileraren poderioz hobetzen den Adimen-Artifizialeko itzultzailea da. Horretarako zure laguntza behar-beharrezkoa da. Beheko eremu puntuduna erabiliz itzuli eta zuzendutako dokumentuak bidali ditzakezu.",
        "tab-doc-correct-disclaimer-greetings": "Aldez aurretik, eskerrik asko.",
        "contact-link": "https://www.batua.eus/kontaktua",
        "offer-help": "Laguntza behar?",
        "language-change-text-login": "Hizkuntza aldatu",
        "welcome-string": "Kaixo eta ongi etorri",
        "form-resetpass-link": "Pasahitza berrezarri",
        "form-email-label": "Emaila",
        "form-email-placeholder": "Idatzi zure emaila",
        "form-password-label": "Pasahitza",
        "form-password-placeholder": "Idatzi zure pasahitza",
        'Sign in': 'Sartu',
        "download-translation-button": "Itzulitako dokumentua jaitsi",
        "form-vicomtech-login-link": "Vicomtech SSO",
        "help": "Laguntza",
        "help-video-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_euskera.mp4",
        "help-video-poster-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_poster_euskera.png",
        "help-contact-action": "Zalantzarik? Bidaliguzu mezu bat",
        "help-contact-url": "https://www.batua.eus/kontaktua",
        "account": "Kontua",
        "copy": "Kopiatu",
        "copy-success": "Itzulpena kopiatuta",
        "evaluate-translation": "Baloratu itzulpena",
        "suggest-correction": "Zuzenketa proposatu",
        "show-keyboard": "Erakutsi teklatua",
        "hide-keyboard": "Ezkutatu teklatua",
        "swap-languages": "Trukatu hizkuntzak",
        "clear-source-text": "Garbitu testua",
        "glossary": "Glosarioa",
        "glossary-none": "Zehaztu gabe",
        "glossary-disclaimer": "Aukeratutako glosarioa jatorrizko eta itzulpen hizkuntzak bere hizkuntza zerrendan badaude bakarrik aplikatuko da.",
        "glossary-unsupported": "Aukeratutako ::glossary:: glosarioa hizkuntza hauekin bakarrik da bateragarria:",
        "glossary-unsupported2": "Aldatu itzulpenaren hizkuntzak glosarioa aplikatu ahal izateko."
    },   
    es: {
        'mainTitle': 'batuago, tu cuenta batua.eus',
        'secondaryTitle': "Interfaz de traducción extendida",
        'batuamantra': "Cada vez que utilizas el traductor Batua.eus contribuyes a mejorar las tecnologías de traducción del Euskera y sus dialectos.",
        'header-kaixo': "Hola",
        'usercard-workgroup': "Grupo de trabajo",
        'usercard-logout': "Salir",
        "footer-usageterms":"Términos de uso",
        "footer-privacypolicy":"Política de privacidad",
        "footer-cookiepolicy":"Política de cookies",
        "footer-usageterms-link":"https://www.batua.eus/es/terminos-de-uso/",
        "footer-privacypolicy-link":"https://www.batua.eus/es/politica-privacidad/",
        "footer-cookiepolicy-link":"https://www.batua.eus/es/politica-cookies/",
        "empty-destination-lang": "Elige el idioma de origen y comienza a escribir",
        "languages-detect": "Detectar idioma",
        "languages-eu": "Euskera - Batua",
        "languages-es": "Castellano",
        "languages-en": "Inglés",
        "languages-fr": "Francés",
        "languages-bz": "Bizkaino",
        "tab-txt": "Texto",
        "tab-doc": "Documentos",
        "tab-doc-correct": "Envía tu corrección",
        "tab-doc-drop-explanation": "Clicka o suelta aquí el documento a traducir",
        "tab-doc-drop-filetypes": "(Formatos de documentos válidos: xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml y md)",
        "tab-doc-file-error-1": "Por favor selecciona un sólo documento y del formato válido.",
        "tab-doc-upload-and-translate-button": "Traducir",
        "tab-doc-working": "Procesando la traducción...",
        "hitza-eta": "y",
        "tab-doc-correct-drop-explanation": "Clicka o suelta aquí el documento con la traducción corregida",
        "tab-doc-correct-drop-filetypes": "(Formatos de documentos válidos: xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml y md)",
        "tab-doc-correct-file-error-1": "Por favor selecciona un único documento y del formato válido.",
        "tab-doc-correct-upload-button": "Enviar corrección",
        "tab-doc-correct-working": "Enviando...",
        "tab-doc-correct-disclaimer": "El traductor Batua.eus es un traductor basado en Inteligencia Artificial que mejora con el uso. Para ello necesitamos tu ayuda. A través del área inferior punteada puedes enviar los documentos traducidos y corregidos.",
        "tab-doc-correct-disclaimer-greetings": "Gracias por tu ayuda.",
        "contact-link": "https://www.batua.eus/es/contacto",
        "offer-help": "¿Necesitas ayuda?",
        "language-change-text-login": "Cambiar idioma",
        "welcome-string": "Kaixo y bienvenido",
        "form-resetpass-link": "Recuperar contraseña",
        "form-email-label": "Email",
        "form-email-placeholder": "Introduce tu email",
        "form-password-label": "Contraseña",
        "form-password-placeholder": "Tu contraseña",
        'Sign in': 'Entrar',
        "download-translation-button": "Descargar traducción",
        "form-vicomtech-login-link": "Vicomtech SSO",
        "help": "Ayuda",
        "help-video-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_castellano.mp4",
        "help-video-poster-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_poster_castellano.png",
        "help-contact-action": "¿Tienes alguna duda? Ponte en contacto con nosotros",
        "help-contact-url": "https://www.batua.eus/es/contacto",
        "account": "Cuenta",
        "copy": "Copiar",
        "copy-success": "Traducción copiada",
        "evaluate-translation": "Valorar la traducción",
        "suggest-correction": "Proponer corrección",
        "show-keyboard": "Abrir teclado",
        "hide-keyboard": "Cerrar teclado",
        "swap-languages": "Intercambiar idiomas",
        "clear-source-text": "Borrar texto",
        "glossary": "Glosario",
        "glossary-none": "Sin especificar",
        "glossary-disclaimer": "El glosario se aplicará únicamente cuando el idioma de origen y traducción estén dentro de los idiomas del glosario.",
        "glossary-unsupported": "El glosario ::glossary:: seleccionado es únicamente compatible con estos idiomas:",
        "glossary-unsupported2": "Cambia los idiomas de la traducción para poder aplicar el glosario."

    },
    en: {
        'mainTitle': 'batuago, your batua.eus account',
        'secondaryTitle': "Extended translation interface",
        'batuamantra': "Every time you translate with Batua.eus, you help us improve the translation technology for the Basque language and it's dialects.",
        'header-kaixo': "Hi",
        'usercard-workgroup': "Workgroup",
        'usercard-logout': "Log Out",
        "footer-usageterms":"Terms of use",
        "footer-privacypolicy":"Privacy policy",
        "footer-cookiepolicy":"Cookie policy",
        "footer-usageterms-link":"https://www.batua.eus/en/terms-of-use/",
        "footer-privacypolicy-link":"https://www.batua.eus/en/privacy-policy/",
        "footer-cookiepolicy-link":"https://www.batua.eus/en/cookie-policy/",
        "empty-destination-lang": "Choose source language and start writing",
        "languages-detect": "Detect language",
        "languages-eu": "Basque - Batua",
        "languages-es": "Spanish",
        "languages-en": "English",
        "languages-fr": "French",
        "languages-bz": "Biscayan",
        "tab-txt": "Text",
        "tab-doc": "Documents",
        "tab-doc-correct": "Submit your correction",
        "tab-doc-drop-explanation": "Click to select or drop the document here to translate",
        "tab-doc-drop-filetypes": "(xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml and md are the supported document types)",
        "tab-doc-file-error-1": "Please select only one document that matches one of the allowed file types.",
        "tab-doc-upload-and-translate-button": "Translate",
        "tab-doc-working": "Translating...",
        "hitza-eta": "and",
        "tab-doc-correct-drop-explanation": "Click to select or drop the corrected translation document here",
        "tab-doc-correct-drop-filetypes": "(xliff, html, docx, odt, xlf, tmx, xml, txt, pptx, xlsx, po, idml and md are the supported document types)",
        "tab-doc-correct-file-error-1": "Please select only one document that matches one of the allowed file types.",
        "tab-doc-correct-upload-button": "Submit correction",
        "tab-doc-correct-working": "Sending...",
        "tab-doc-correct-disclaimer": "Batua.eus is a translation engine that improves every time you use it. Your help is crucial. You can submit translation corrections using the dotted area below.",
        "tab-doc-correct-disclaimer-greetings": "Thank you for your support.",
        "contact-link": "https://www.batua.eus/en/contact/",
        "offer-help": "Need help?",
        "language-change-text-login": "Change language",
        "welcome-string": "Hi there, Welcome",
        "form-resetpass-link": "Reset password",
        "form-email-label": "Email",
        "form-email-placeholder": "Type your email",
        "form-password-label": "Password",
        "form-password-placeholder": "Your password",
        'Sign in': 'Log In',
        "download-translation-button": "Download Translation",
        "form-vicomtech-login-link": "Vicomtech SSO",
        "help": "Help",
        "help-video-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_castellano.mp4",
        "help-video-poster-url": "https://cdn.vicomcloud.net/media/batua/tutorial/batuago_poster_castellano.png",
        "help-contact-action": "Questions? Contact us",
        "help-contact-url": "https://www.batua.eus/en/contact",
        "account": "Account",
        "copy": "Copy",
        "copy-success": "Translation copied",
        "evaluate-translation": "Evaluate translation",
        "suggest-correction": "Suggest correction",
        "show-keyboard": "Show keyboard",
        "hide-keyboard": "Hide keyboard",
        "swap-languages": "Swap languages",
        "clear-source-text": "Clear text",
        "glossary": "Glossary",
        "glossary-none": "Unspecified",
        "glossary-disclaimer": "The glossary will only be applied if the source and translation languages appear in the glossary language list.",
        "glossary-unsupported": "The selected ::glossary:: glossary is only supported by these languages:",
        "glossary-unsupported2": "Change the translation's languages to apply the glossary."
    }
  };

  export default languages;
