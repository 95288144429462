import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import React, { useRef, useState, useLayoutEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import * as Variables from "./Variables"
import { I18n } from '@aws-amplify/core';

function LeftTextArea({ enteredText, setEnteredText }) {
  const textareaRef = useRef(null);

  // const [refVisible, setRefVisible] = useState(false)

  // useEffect(() => {
  //   // console.log(textareaRef.current);

  //   if (textareaRef){
  //     setRefVisible(true)
  //   }

  // }, [textareaRef]);



  // useEffect(() => {
  //   const resizeTextarea = () => {
  //     textareaRef.current.style.height = "auto";
  //     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  //   };

  //   if (textareaRef.current) {
  //     textareaRef.current.addEventListener("input", resizeTextarea, false);

  //     // Clean up the event listener when the component unmounts
  //     return () => {
  //       textareaRef.current.removeEventListener("input", resizeTextarea, false);
  //     };
  //   }
  // }, []);

  const [charCount, setCharCount] = useState(0);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const handleInputChange = (e) => {
    if (e.target.value.length <= Variables.MaxCharsOnTranslation) {
      adjustHeight();
      setEnteredText(e.target.value);
      setCharCount(e.target.value.length);
    }
  };
  const clearTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
      textareaRef.current.style.height = "auto";
    }

    setEnteredText("");
    setCharCount(0);
  };

  function adjustHeight() {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);

  const handleChange = (input) => {
    setEnteredText(input);
    console.log("Input changed", input);
  };

  const handleKeyPress = (button) => {
    console.log("Button pressed", button);
  };

  const toggleKeyboard = () => {
    setKeyboardOpen(!keyboardOpen);
  };

  const focusTextArea = () => {
    textareaRef.current && textareaRef.current.focus()
  }

  return (
    <Box>
      <Box
        sx={{
          border: 1,
          padding: "0.25rem",
          borderColor: "divider",
          minHeight: "175px",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          color: "#282828"
        }}
        onClick={focusTextArea}
      >
        <div className="input-container" style={{ display: "flex", justifyContent: "space-between", borderTopLeftRadius: "inherit"}}>
          {/* {refVisible && ( */}
            <textarea
            value={enteredText}
            rows="1"
            style={{
              border: "none",
              borderTopLeftRadius: "inherit",
              outline: "none",
              padding: "10px",
              fontSize: "20px",
              width: "100%",
              height: "auto",
              resize: "none",
              /* Firefox shows scrollbar on growth, you can hide like this. */
              overflow: "hidden"
            }}
            ref={textareaRef}
            className="dynamic-textarea"
            id="sourceTextInput"
            onChange={handleInputChange}
          ></textarea>
          
          { enteredText.length > 0 && (
            <Tooltip title={I18n.get("clear-source-text")} style={{ height: "40px", width: "40px" }}>
              <IconButton onClick={clearTextarea}>
                <CloseIcon sx={{ color: "gray" }} />
              </IconButton>
            </Tooltip>
          )}
          
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          
          <div style={{ marginBottom: "0rem", marginLeft: "0rem" }}>
            {/*
            <IconButton>
              <MicIcon sx={{ color: "gray" }} />
            </IconButton>
            {charCount > 0 ? (
              <IconButton>
                <VolumeUpIcon sx={{ color: "gray" }} />
              </IconButton>
            ) : (
              ""
            )}
            */}
          </div>
            
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="span"
              sx={{ color: "gray", fontSize: "12px", mt: "5px", mr: "0.25rem" }}
            >
              {charCount}/{Variables.MaxCharsOnTranslation}
            </Typography>
            <Tooltip title={keyboardOpen ? I18n.get("hide-keyboard") : I18n.get("show-keyboard")}>
              <IconButton onClick={toggleKeyboard}>
                <KeyboardIcon sx={{ color: "gray" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
      {keyboardOpen === true ? (
        <Keyboard onChange={handleChange} onKeyPress={handleKeyPress} />
      ) : (
        ""
      )}
    </Box>
  );
}

export default LeftTextArea;
