import { Box, IconButton, Typography, List, ListItemText, Avatar, ListItem } from "@mui/material";
import { I18n } from "@aws-amplify/core";
import LoadingButton from "@mui/lab/LoadingButton";
// import LinkIcon from "@mui/icons-material/Link";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Link from "@mui/material/Link";
import React, { useEffect, useState, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { toTitleCase } from "./Utils";
import { languagesFromData, languagesToData } from "./Variables";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";

import { post } from "aws-amplify/api";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { fetchUserAttributes } from "aws-amplify/auth";
// import { useAuthenticator } from "@aws-amplify/ui-react";

import { uploadData, isCancelError } from "aws-amplify/storage";

import { makeTmpFilePathDocTranslation } from "./Utils";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#2CA3AF",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const buttonStyles = {
  textTransform: "none",
  fontWeight: "bold",
  borderColor: "#e3e3e3",
  borderRadius: "4px",
  marginRight: "8px",
  // width: "auto",
};

function DocUploadArea(props) {
  const [currentTranslationId, setCurrentTranslationId] = useState(null);
  const [isDocProcessing, setIsDocProcessing] = useState("0"); // "0"=not processing // "1"=processing // "2"=finished and ready to download // "9"=error
  const [docProcessingStatusRetrySwitch, setDocProcessingStatusRetrySwitch] = useState(false);
  const [docTranslationDownloadUrl, setDocTranslationDownloadUrl] = useState("");
  const [isDocProcessingError, setIsDocProcessingError] = useState(false);
  const [docProcessingErrorInfo, setDocProcessingErrorInfo] = useState("");
  const [acceptedFilesState, setAcceptedFilesState] = useState([]);
  const [rejectedFilesState, setRejectedFilesState] = useState([]);

  const [translateFromLanguag, setTranslateFromLanguag] = useState("");
  const [translateToLanguag, setTranslateToLanguag] = useState("");

  const [translateToLanguageList, setTranslateToLanguageList] = useState([]);

  // const { user } = useAuthenticator((context) => [context.user]);

  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [userOrg, setUserOrg] = useState("");
  // const [cognitoIdentityId, setCognitoIdentityId] = useState("");

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      // "application/rtf": [],
      // "text/rtf": [],
      // "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/vnd.oasis.opendocument.text": [],
      "application/x-xliff+xml": [],
      "application/x-xliff": [],
      "text/html": [],
      "application/xml": [],
      "text/xml": [],
      "text/plain": [".txt", ".po"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/x-gettext-translation": [],
      "application/vnd.adobe.indesign-idml-package": [],
      "text/markdown": [],
      "text/x-markdown": [],
    },
    maxFiles: 1,
    noClick: isDocProcessing === "1",
    onDrop: (acceptedFiles) => {
      setAcceptedFilesState(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // Reset status
      setIsDocProcessing("0");
      setIsDocProcessingError(false)
      setDocProcessingErrorInfo("")
      setTranslateFromLanguag("")
      setTranslateToLanguag("")
      setDocTranslationDownloadUrl("")
    },
  });

  function languagesToFilterFunction() {
    var sliceToReturn = [];
    var availableToLanguageIds = [];
    // Pick TO languages from
    languagesFromData.forEach(function (valueFrom) {
      // Search the active from and get its data
      if (valueFrom.id === translateFromLanguag) {
        // Get availableto data
        availableToLanguageIds = valueFrom.availableto;
      }
    });

    //
    languagesToData.forEach(function (valueFrom) {
      if (availableToLanguageIds.includes(valueFrom.id)) {
        sliceToReturn.push(valueFrom);
      }
    });
    setTranslateToLanguageList(sliceToReturn);
  }

  function handleFromLanguageChange(e) {
    setTranslateFromLanguag(e.target.value);
  }

  function handleToLanguageChange(e) {
    setTranslateToLanguag(e.target.value);
  }

  // When from language changes adapt To languages
  useEffect(() => {
    // Reset selected to language
    setTranslateToLanguag("");
    // Execute filtering and TO language array set
    languagesToFilterFunction();
  }, [translateFromLanguag]);

  // Manage status check retries
    useEffect(() => {
      
      if (docProcessingStatusRetrySwitch === true){

        // Wait some seconds
        setTimeout(function() {
          // Set status check switch to false
          setDocProcessingStatusRetrySwitch(false)
          //Launch status check
          handleSubmitDocTranslationStep3(currentTranslationId)
        }, 5000);
        
       
      }
      

    }, [docProcessingStatusRetrySwitch]);

  // useEffect(() => {
  //   // Set cognito id
  //   // console.log(user)
  //   // handleFetchUserAttributes()

  //   setCognitoIdentityId("asdf")
  // }, [user]);

  function clearAll() {
    // Clear and reset
    setAcceptedFilesState([]);
    setRejectedFilesState([]);
    setIsDocProcessing("0");
    setIsDocProcessingError(false)
    setDocProcessingErrorInfo("")
    setDocTranslationDownloadUrl("")
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    // Get user attributes
    handleFetchUserAttributes();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      // console.log(userAttributes)
      setUserEmailAddress(userAttributes.email);
      if (userAttributes.zoneinfo && userAttributes.zoneinfo !== "") {
        setUserOrg(toTitleCase(userAttributes.zoneinfo));
      }else{
        setUserOrg("vicomtech");
      }
    } catch (error) {
      // console.log(error);
      // TODO logout
    }
  }

  async function handleSubmitDocTranslationStep1() {
    // First upload data to temp folder in storage (s3)
    const randomString = makeTmpFilePathDocTranslation(20);
    const timestamp = Math.round(new Date().getTime() / 1000);
    const filenamePath = randomString + "-" + timestamp + "." + acceptedFilesState[0].name.split(".").pop(); //
    setIsDocProcessing("1");
    const uploadTask = await uploadData({
      key: filenamePath,
      data: acceptedFilesState[0],
      options: {
        accessLevel: "guest", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
        // onProgress // Optional progress callback.
        contentType: acceptedFilesState[0].type, // (String) The default content-type header value of the file when downloading it.
        // contentEncoding: "compress", // (String) The default content-encoding header value of the file when downloading it.
        contentDisposition: "attachment", // (String) Specifies presentational information for the object
        // metadata?: {key: "value"}, // (map<String>) A map of metadata to store with the object in S3.
        // useAccelerateEndpoint: true, // Whether to use accelerate endpoint.
      },
    });
    try {
      await uploadTask.result;
      // await uploadTask.cancel(); // TODO set timeout
      // console.log("Doc upload Succeeded: ", uploadTask.result);

      // Now call API and create translation job
      // const
      handleSubmitDocTranslationStep2(filenamePath)

    } catch (error) {
      if (isCancelError(error)) {
        // Handle error thrown by task cancellation
        // console.log("Doc upload Cancelled: ", error);
        setIsDocProcessingError(true);
        setDocProcessingErrorInfo("error 1004: Cancelled");
      }else{
        setIsDocProcessingError(true);
        setDocProcessingErrorInfo("error 1003: "+error);
      }
    }

  }

  async function handleSubmitDocTranslationStep2(filepath) {

    try {
      const docTranslationRequest = { from: translateFromLanguag, to: translateToLanguag, user: userEmailAddress, workgroup: userOrg, filepath: filepath };
      /*
      	FromLanguage    string `json:"from"`
        ToLanguage      string `json:"to"`
        TranslationFilePath string `json:"filepath"`
        Workgroup       string `json:"workgroup"`
        UserEmail       string `json:"user"`
      */

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/v1/api/translationdoc",
        options: {
          body: docTranslationRequest,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && bodyJson.success){
        // Get translation id
        setCurrentTranslationId(bodyJson.id)
        // Now start checking status
        handleSubmitDocTranslationStep3(bodyJson.id)
       
      }else{
        // Response was not the expected one
        setIsDocProcessing("9");
        setIsDocProcessingError(true);
        setDocProcessingErrorInfo("error 1002");
      }


    } catch (err) {
      
      // Finally set isprocessing to false
      setIsDocProcessing("9");
      setIsDocProcessingError(true);
      setDocProcessingErrorInfo("error 1001");
    }
  }

  async function handleSubmitDocTranslationStep3(translationid){ //
    // /v1/api/translationdocstatus

    try {
      const docStatusRequest = { id: translationid, workgroup: userOrg };
      /*
	Id        string `json:"id"`
	Workgroup string `json:"workgroup"`
      */

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/v1/api/translationdocstatus",
        options: {
          body: docStatusRequest,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();

      if (statusCode === 200){

        // Check status value
        if (bodyJson.status === "done") {


          // Stop retry switch
          setDocProcessingStatusRetrySwitch(false)
          // Show file download icon
          setIsDocProcessing("2")
          // Save download url
          setDocTranslationDownloadUrl(bodyJson.file)
        

        }else if (bodyJson.status === "error") {


          // Finally set isprocessing to false
          setIsDocProcessing("9");
          setIsDocProcessingError(true);
          setDocProcessingErrorInfo(bodyJson.attention);
          setDocProcessingStatusRetrySwitch(false)

        }else if (bodyJson.status === "processing") {


          // Keep requesting status
          setDocProcessingStatusRetrySwitch(true)
          // handleSubmitDocTranslationStep3(translationid)

        }else{

          // Should not happen
          // Set isprocessing to false
          setIsDocProcessing("9");
          setIsDocProcessingError(true);
          setDocProcessingErrorInfo("Unknown error");
          setDocProcessingStatusRetrySwitch(false)
        }
      }else{

      // Finally set isprocessing to false
      setIsDocProcessing("9");
      setIsDocProcessingError(true);
      setDocProcessingErrorInfo("error 2001");
      setDocProcessingStatusRetrySwitch(false)
    }

      


    } catch (err) {
      
      // Finally set isprocessing to false
      setIsDocProcessing("9");
      setIsDocProcessingError(true);
      setDocProcessingErrorInfo("error 2001");
      setDocProcessingStatusRetrySwitch(false)
    }



  }



  return (
    // <Box>
    <Box
      sx={{
        border: 1,
        padding: 1,
        borderColor: "divider",
        // width: "98%",
        minHeight: "150px",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // backgroundColor: "",
      }}
    >
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} disabled={isDocProcessing === "1"} />
          <CloudUploadIcon />
          <p>{I18n.get("tab-doc-drop-explanation")}</p>
          <em>{I18n.get("tab-doc-drop-filetypes")}</em>
        </div>
        {/* <aside> */}
        {acceptedFilesState.length > 0 && (
          <div className="">
            <List dense={true}>
              <ListItem disableGutters>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 0 }} justify="flex-end" alignItems="center">
                  <Grid item xs={12} sm={12} md={5}>
                    <Stack direction={"horizontal"} justifyContent="right" justify="flex-end" alignItems="center">
                      <Avatar variant="rounded" sx={{ width: 32, height: 32, bgcolor: "#ffffff" }}>
                        <IconButton
                          // variant="outlined"
                          color="warning"
                          disabled={isDocProcessing === "1"}
                          // style={buttonStyles}
                          onClick={() => clearAll()}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Avatar>
                      <ListItemText primary={acceptedFilesState[0].path} secondary={acceptedFilesState[0].size + " bytes"} />
                    </Stack>
                  </Grid>
                  { (isDocProcessing === "0" || isDocProcessing === "1") && (
                    <>
                    <Grid item xs={12} sm={7} md={4}>
                    <Stack
                      direction={"horizontal"}
                      sx={{ justifyContent: { xs: "center", sm: "right", md: "right" }, mt: { xs: "1rem", sm: "0rem", md: "0rem" } }}
                    >
                      <FormControl sx={{ m: 0.2, minWidth: 100 }} variant="standard" size="small" item>
                        {/* <InputLabel id="demo-simple-select-helper-label">Jatorrizkoa</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={translateFromLanguag}
                          label="Aukeratu"
                          onChange={handleFromLanguageChange}
                          disabled={isDocProcessing === "1"}
                        >
                          {languagesFromData.map((lang) => (
                            <MenuItem value={lang.id}>{I18n.get("languages-" + lang.id)}</MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>Nondik</FormHelperText> */}
                      </FormControl>
                      <KeyboardDoubleArrowRightIcon color="primary" />
                      <FormControl sx={{ m: 0.2, minWidth: 100 }} variant="standard" size="small" item>
                        {/* <InputLabel id="demo-simple-select-helper-label">Jatorrizkoa</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={translateToLanguag}
                          label="Aukeratu"
                          onChange={handleToLanguageChange}
                          disabled={translateToLanguageList.length === 0 || isDocProcessing === "1"}
                        >
                          {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem> */}
                          {translateToLanguageList.map((lang) => (
                            <MenuItem value={lang.id}>{I18n.get("languages-" + lang.id)}</MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>Nora</FormHelperText> */}
                      </FormControl>
                      {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} /> */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <Stack
                      direction={"horizontal"}
                      sx={{ justifyContent: { xs: "center", sm: "left", md: "right" }, mt: { xs: "1rem", sm: "0rem", md: "0rem" } }}
                    >
                      <LoadingButton
                        key={1}
                        variant="outlined"
                        style={buttonStyles}
                        sx={{ pl: { xs: "3rem", sm: "3rem", md: "2rem" }, pr: { xs: "3rem", sm: "3rem", md: "2rem" } }}
                        startIcon={<SendIcon />}
                        // onClick={() => setIsDocProcessing(!isDocProcessing)}
                        onClick={() => handleSubmitDocTranslationStep1()}
                        loading={isDocProcessing === "1"}
                        loadingPosition="start"
                        disabled={translateFromLanguag !== "" && translateToLanguag !== "" ? false : true}
                      >
                        <span>{isDocProcessing !== 1 ? I18n.get("tab-doc-upload-and-translate-button") : I18n.get("tab-doc-working")}</span>
                      </LoadingButton>
                    </Stack>
                  </Grid>
                    </>
                  )}
                  {isDocProcessing === "2" && (
                    <>
                    <Grid item xs={12} sm={12} md={7}>
                    <Stack
                      direction={"horizontal"}
                      sx={{ justifyContent: { xs: "center", sm: "left", md: "right" }, mt: { xs: "1rem", sm: "0rem", md: "0rem" } }}
                    >
                      <LoadingButton
                        key={1}
                        variant="text"
                        style={buttonStyles}
                        sx={{ pl: { xs: "3rem", sm: "3rem", md: "2rem" }, pr: { xs: "3rem", sm: "3rem", md: "2rem" } }}
                        startIcon={<SaveAltIcon />}
                        // onClick={() => setIsDocProcessing(!isDocProcessing)}
                        // onClick={() => handleSubmitDocTranslationStep1()}
                        // loading={isDocProcessing === "1"}
                        loadingPosition="start"
                        // disabled={translateFromLanguag !== "" && translateToLanguag !== "" ? false : true}
                      >
                        <span>
                          {/* <a target="_blank" href={docTranslationDownloadUrl}>{I18n.get("download-translation-button")}</a> */}
                          <Link color="inherit" href={docTranslationDownloadUrl} target="_blank">{I18n.get("download-translation-button")}</Link>
                        </span>
                      </LoadingButton>
                    </Stack>
                      </Grid></>
                  )}
                  { isDocProcessing === "9" && (
                    <>Error</>
                  )}
                  
                </Grid>
              </ListItem>
            </List>
          </div>
        )}
        {rejectedFilesState.length > 0 && (
          <>
            <Typography variant="span" color="red">
              {I18n.get("tab-doc-file-error-1")}
            </Typography>
          </>
        )}
        {/* </aside> */}
      </section>
    </Box>
    // </Box>
  );
}

export default DocUploadArea;
