import { Box } from "@mui/material";
import React from "react";

import DocUploadArea from "./DocUploadArea";

function DocTranslate() {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        flexDirection: { xs: "column", md: "row" },
        m: "1rem",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DocUploadArea />
      </Box>


    </Box>
  );
}

export default DocTranslate;
