export const MaxCharsOnTranslation = 10000;

export const languagesFromData = [
    { id: "eu", langId: "eu_EU", availableto: ["es", "en", "fr", "bz"] },
    { id: "es", langId: "es_ES", availableto: ["eu", "bz"] },
    { id: "en", langId: "en_EN", availableto: ["eu"] },
    { id: "fr", langId: "fr_FR", availableto: ["eu"] },
    { id: "bz", langId: "eu_BIZ", availableto: ["es", "eu"] },
];

export const languagesToData = [
    { id: "eu" },
    { id: "es" },
    { id: "en" },
    { id: "fr" },
    { id: "bz" },
];
