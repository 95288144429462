import { I18n } from '@aws-amplify/core';
import { styled } from '@mui/system';
import { IconButton, Menu, MenuItem, Tab, Tabs, ListItemIcon, ListItemText, Box, Typography, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { useState, useEffect } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const SpinAutoModeIcon = styled(AutoModeIcon)({
  "@keyframes spin": {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  },
  animation: 'spin 2s linear infinite'
});

function LanguageDropdown({ currentLanguage, setLanguage, languages, autolang_available=false, autolang="", setAutolang=null, autolang_loading=false, showLangSwap=false, langSwap=false, handleLangSwap="", swapToLang="" }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(autolang === 'detect' ? "detect" : currentLanguage);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (currentLanguage !== tabValue){
      if(autolang_available && tabValue === 'detect'){
        setAutolang();
      }else if(autolang_available){
        setLanguage(tabValue);
      }else{
        setLanguage(tabValue);
      }
      setAnchorEl(null);
    }
  }, [tabValue]);

  useEffect(() => {
    if(!autolang_available && currentLanguage !== "" && currentLanguage !== tabValue){
      setTabValue(currentLanguage);
    }
    setAnchorEl(null);
  }, [currentLanguage]);

  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Tabs
        style={{ 
          margin: "0 8px",
          minHeight: "50px"
        }}
        value={autolang_available ? tabValue : currentLanguage}
        onChange={(event, newValue) => setTabValue(newValue)}
        aria-label="pick translation language"
      >
        {autolang_available && (
          <Tab
            sx={{
              textTransform: "none",
              fontWeight: 600,
              display: {
                xs: (autolang !== "")  ? "flex" : "none",
                xl: "flex",
              }
            }}
            icon={autolang_loading ? <SpinAutoModeIcon fontSize='small' /> : <AutoAwesomeIcon fontSize='small'/> }
            iconPosition="start"
            label={autolang === '' ? I18n.get("languages-detect") : I18n.get("languages-"+autolang)}
            value={"detect"}
            style={{
              alignItems: 'center', 
              flexDirection: 'row', 
              justifyContent: 'center',
              minHeight: '50px',
              height: '50px'
            }}
            key='detect'
          />
        )}
        {languages.length > 0 && (
        languages.map((lang) => (
          <Tab
            sx={{
              textTransform: "none",
              fontWeight: 600,
              display: {
                xs: (autolang === "" && currentLanguage === lang.id) ? "flex" : "none",
                xl: "flex",
              },
              alignItems: 'center', flexDirection: 'row', justifyContent: 'center'
            }}
            label={I18n.get("languages-"+lang.id)}
            value={lang.id}
            key={lang.id}
          />
        )))}
        
        {languages.length > 0 ? 
          <IconButton sx={{ width: 40, height: 40, margin: 'auto 0'}} onClick={handleClick}>
            <ExpandMoreOutlinedIcon />
          </IconButton>
          :
          <Tab
            sx={{
              textTransform: "none"
            }}
            label={I18n.get("empty-destination-lang")}
            style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}
            disabled
            key="disabled"
          />
        }
        
      </Tabs>
      
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
      {autolang_available && (
        <MenuItem onClick={() => setTabValue('detect')} key='detect' selected={tabValue === 'detect'}>
          <ListItemIcon>
            {tabValue === "detect" && (
              <CheckIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText style={{marginRight:"5px"}}>{I18n.get("languages-detect")}</ListItemText>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            <AutoAwesomeIcon fontSize="small" />
          </Typography>
        </MenuItem>
      )}
      {languages.map((lang) => (
        <MenuItem onClick={() => setTabValue(lang.id)} key={lang.id} selected={(autolang === "" && currentLanguage === lang.id)} >
            <ListItemIcon>
            {(autolang === "" && currentLanguage === lang.id) && (
              <CheckIcon fontSize="small" />
            )}
            </ListItemIcon>
          {I18n.get("languages-"+lang.id)}
        </MenuItem>
      ))}
    </Menu>
    { showLangSwap && (
      <Tooltip 
        disabled={!langSwap} 
        title={I18n.get("swap-languages")} 
      >
        <IconButton sx={{ width: 40, height: 40 }} onClick={() => {setTabValue(swapToLang); handleLangSwap();}}>
          <SwapHorizIcon />
        </IconButton>
      </Tooltip>
      
    )}
    </Box>
  </>
  );
}

export default LanguageDropdown;
