  export function toTitleCase(str) {
    if (!str) {
        return ""
    }
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  export function makeTmpFilePathDocTranslation(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  export function getGlossaryFromId(id, glossaryList) {
    let result = {
      id: "",
      displayName: "",
      languages: []
    }
    let found = glossaryList.find((obj) => obj.id === id)
    if (found){
      return found;
    }
    return result;
  }

