import React from "react";
import { I18n } from '@aws-amplify/core';
import VicomtechBegia from "../images/vicomtech_begia.png";
import { Image } from "@aws-amplify/ui-react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

export default function Footer() {

  const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || "v0.0.0"; 

  return (

    <Box
      component="footer"
      sx={{
        p: '1.5rem',
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "space-between"},
        alignItems: { xs: "center"},
        textAlign: "center"
      }}
    >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: "0.25rem", lg: "1rem" },
          }}
        >
          <Typography 
            variant="body1"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Image
              alt="Vicomtech.org begia Logoa"
              src={VicomtechBegia}
              objectFit="cover"
              backgroundColor="initial"
              height="20px"
              width="20px"
              opacity="100%"
              padding="0px 3px 3px 0px"
            />
            <Link color="primary" href="https://www.vicomtech.org/" target="_blank" style={{ textDecoration: "none" }}>
              Vicomtech, your R&D partner for smart digital solutions.
            </Link>
          </Typography>

          <Typography variant="body2" color="text.primary">
            {"Copyright © "}
            Vicomtech {new Date().getFullYear()}
            {" - "}{BUILD_VERSION}
          </Typography>
        </Box>

        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: "0.25rem", lg: "1rem" },
          }}
        >
          <Link color="secondary" href={I18n.get("footer-usageterms-link")} target="_blank">
            <Typography variant="body2">
              {I18n.get("footer-usageterms")}
            </Typography>
          </Link>
          <Link color="secondary" href={I18n.get("footer-privacypolicy-link")} target="_blank">
            <Typography variant="body2">
              {I18n.get("footer-privacypolicy")}
            </Typography>
          </Link>
          <Link color="secondary" href={I18n.get("footer-cookiepolicy-link")} target="_blank">
            <Typography variant="body2">
              {I18n.get("footer-cookiepolicy")}
            </Typography>
          </Link>
        </Box>
    </Box>
  );
}
